import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import { withRequest } from '../extensions';
import { Role } from '../types';
import { RootStore } from './RootStore';
import { User } from './User';

export const UserStore = t
  .model('UserStore', {
    me: t.maybeNull(User),
  })
  .extend(withRequest)
  .actions((self) => {
    const { request } = self;
    const { companyStore, serviceStationStore } =
      getRoot<typeof RootStore>(self);

    return {
      fetchMe: flow(function* () {
        try {
          const promise = request({
            method: 'GET',
            url: '/api/users/me',
          });

          const { data } = yield promise;

          if (data.is_superuser || data.role >= Role.CompanyAdmin) {
            companyStore.fetchCompanies();
          }

          if (data.role === Role.ServiceStation) {
            serviceStationStore.fetchMyServiceStation();
          }

          self.me = data;
        } catch (error) {
          console.error('Failed to fetch user', error);
        }
      }),
    };
  })
  .actions((self) => ({
    reset() {
      applySnapshot(self, {
        me: null,
      });
    },
  }));
