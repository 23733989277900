import { types as t } from 'mobx-state-tree';
import { BidIncrementInterval } from './BidIncrementInterval';

export const Company = t.model('Company', {
  id: t.identifier,
  name: t.string,
  logo: t.maybeNull(t.string),
  auction_end_dt_incrementation: t.number,
  default_end_dt_increment_days: t.number,
  default_end_dt_increment_time: t.string,
  bid_increment_intervals: t.array(BidIncrementInterval),
  email: t.maybeNull(t.string),
  phone: t.maybeNull(t.string),
  street: t.maybeNull(t.string),
  postal: t.maybeNull(t.string),
  city: t.maybeNull(t.string),
  ended_auction_email: t.maybeNull(t.string),
  auction_summary_email: t.maybeNull(t.string),
  next_auction_number: t.string,
});
