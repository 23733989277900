import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { ReactNode, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox,
  Input,
  PrimaryButton,
  Spinner,
  LinkButton,
  Modal,
  DropdownSelect,
} from '../components';
import { useStore } from '../hooks';
import { AuctionInstance } from '../models';
import { formatDate } from '../utils/formatDate';
import { DAMAGE_CONDITION_TEXTS, Role } from '../types';
import classNames from 'classnames';
import { formatCurrency } from '../utils/formatCurrency';
import { errorMessageHandler } from '../utils/errorMessageHandler';

const AuctionDataCell = ({
  label,
  value,
  fullWidth,
}: {
  label: string;
  value: ReactNode | null;
  fullWidth?: boolean;
}) => (
  <div
    className={classNames('px-4 py-05', {
      'w-50': !fullWidth,
      'w-100': fullWidth,
    })}
  >
    <p className="text-sm text-gray m-0">{label}</p>
    <p className="m-0">{value}</p>
  </div>
);

type FormData = {
  bid: number;
  has_accepted_terms: boolean;
};

export const AuctionsDetailPage = observer(() => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { auctionStore, userStore, bidStore, authStore } = useStore();

  const state = useLocalObservable<{
    auction: AuctionInstance | null;
    bids: any;
    isFetchingMoreBids: boolean;
    isSubmiting: boolean;
    activeImage: string | null;
    errorMessages: string[];
    bidSuccess: boolean;
    bidId: string | null;
    isDeletingBid: boolean;
    hasDeleteBidError: boolean;
    deleteBidSuccess: boolean;
    isSelectingWinner: boolean;
    hasSelectWinnerError: boolean;
    selectWinnerSuccess: boolean;
    isCancelingAuction: boolean;
    hasCancelAuctionError: boolean;
    cancelAuctionSuccess: boolean;
    isDeletingAuction: boolean;
    hasDeleteAuctionError: boolean;
    deleteAuctionSuccess: boolean;
    isNotifyingWinner: boolean;
    notifyWinnerSuccess: boolean;
  }>(() => ({
    auction: null,
    bids: null,
    isFetchingMoreBids: false,
    isSubmiting: false,
    activeImage: null,
    errorMessages: [],
    bidSuccess: false,
    bidId: null,
    isDeletingBid: false,
    hasDeleteBidError: false,
    deleteBidSuccess: false,
    isSelectingWinner: false,
    hasSelectWinnerError: false,
    selectWinnerSuccess: false,
    isCancelingAuction: false,
    hasCancelAuctionError: false,
    cancelAuctionSuccess: false,
    isDeletingAuction: false,
    hasDeleteAuctionError: false,
    deleteAuctionSuccess: false,
    isNotifyingWinner: false,
    notifyWinnerSuccess: false,
  }));

  useEffect(() => {
    const ws = new WebSocket(`${process.env.WEBSOCKET_URL}/ws/auction/${id}/`);
    ws.onopen = () => {
      // It's not possible to add any auth header in a websocket connection,
      // so when the connection is open we send the token to the server to check if it's valid.
      ws.send(JSON.stringify({ token: authStore.access_token }));
    };
    ws.onmessage = (event) => state.auction!.handleNewBidMessage(event.data);

    return () => {
      if (ws.readyState === WebSocket.OPEN) ws.close();
    };
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>();

  const onSubmit = handleSubmit(async ({ ...data }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.errorMessages = [];
    state.bidSuccess = false;

    try {
      data.has_accepted_terms =
        userStore.me?.has_accepted_terms || data.has_accepted_terms;
      await auctionStore.bidAuction(id!, data);
      state.bidSuccess = true;
    } catch (error: any) {
      state.errorMessages = errorMessageHandler(error.response.data);
    } finally {
      reset();
      state.isSubmiting = false;
      state.auction = await auctionStore.fetchAuction(id!);
      await userStore.fetchMe();
    }
  });

  const fetchAuction = async () => {
    const auction = await auctionStore.fetchAuction(id!);
    state.auction = auction;
    if (auction.image) {
      state.activeImage = auction.image;
    }
  };

  const fetchBids = async () => {
    state.bids = await bidStore.fetchBids(id!);
  };

  const fetchMoreBids = () => {
    if (state.isFetchingMoreBids) return;

    state.isFetchingMoreBids = true;
    bidStore
      .fetchBids(id!, true)
      .then(() => (state.isFetchingMoreBids = false));
  };

  const deleteBid = async () => {
    state.isDeletingBid = true;
    state.hasDeleteBidError = false;
    state.deleteBidSuccess = false;

    if (state.bidId) {
      try {
        await bidStore.deleteBid(id!, state.bidId);
        state.deleteBidSuccess = true;
      } catch (e) {
        state.hasDeleteBidError = true;
      } finally {
        state.bidId = null;
        state.isDeletingBid = false;
      }

      fetchBids();
    }
  };

  const selectWinner = async () => {
    state.isSelectingWinner = true;
    state.hasSelectWinnerError = false;
    state.selectWinnerSuccess = false;

    if (state.bidId) {
      try {
        await bidStore.selectBidAsWinner(id!, state.bidId);
        state.selectWinnerSuccess = true;
      } catch (e) {
        state.hasSelectWinnerError = true;
      } finally {
        state.bidId = null;
        state.isSelectingWinner = false;
      }

      fetchAuction();
      fetchBids();
    }
  };

  const cancelAuction = async () => {
    if (state.auction === null || state.isCancelingAuction) return;

    state.isCancelingAuction = true;
    state.hasCancelAuctionError = false;
    state.cancelAuctionSuccess = false;

    try {
      await auctionStore.cancelAuction(state.auction!.id);
      state.cancelAuctionSuccess = true;
    } catch (e) {
      state.hasCancelAuctionError = true;
    } finally {
      state.isCancelingAuction = false;
    }

    fetchAuction();
  };

  const deleteAuction = async () => {
    if (state.auction === null || state.isDeletingAuction) return;

    state.isDeletingAuction = true;
    state.hasDeleteAuctionError = false;
    state.deleteAuctionSuccess = false;

    try {
      await auctionStore.deleteAuction(state.auction!.id);
      state.deleteAuctionSuccess = true;
    } catch (e) {
      state.hasDeleteAuctionError = true;
    } finally {
      state.isDeletingAuction = false;
    }
  };

  const notifyWinner = async () => {
    state.isNotifyingWinner = true;
    state.notifyWinnerSuccess = false;

    try {
      await auctionStore.notifyWinner(id!);
      state.notifyWinnerSuccess = true;
    } catch (error) {
      state.notifyWinnerSuccess = false;
    } finally {
      state.isNotifyingWinner = false;
    }

    fetchAuction();
  };

  const dateTimeString = (d: Date) => {
    const { date, time } = formatDate(d);

    return `${date} ${time}`;
  };

  useEffect(() => {
    fetchAuction();

    if (userStore.me!.role >= Role.CaseManager) {
      fetchBids();
    }
  }, [id]);

  const { hasMore } = bidStore;

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
    {
      title: 'Auktioner',
      href: '/auctions',
    },
  ];

  if (state.auction === null || userStore.me === null) return null;

  return (
    <div>
      <Breadcrumbs
        items={breadcrumbs}
        active={`${state.auction.manufacturer} ${state.auction.model || ''}`}
      />
      <h1 className="display-2 mt-15">
        {state.auction.manufacturer} {state.auction.model}
      </h1>
      <div className="d-flex mt-2">
        <div className="flex-shrink-0" style={{ width: 680 }}>
          <img
            src={state.activeImage ?? require('../assets/placeholder.jpeg')}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: 442,
            }}
          />
          {state.auction.additional_images &&
            state.auction.additional_images.length > 0 && (
              <div className="d-flex overflow-auto mr-025 my-05">
                {state.auction.thumb !== null && (
                  <img
                    src={state.auction.thumb}
                    height={78}
                    className="mr-025"
                    onClick={() => (state.activeImage = state.auction!.image)}
                    role="button"
                  />
                )}
                {state.auction.additional_images.map((image) => (
                  <img
                    key={image.id}
                    src={image.thumb}
                    height={78}
                    className="mr-025"
                    onClick={() => (state.activeImage = image.image)}
                    role="button"
                  />
                ))}
              </div>
            )}
          {state.auction.description && (
            <div className="bg-white py-1 px-4 mt-05">
              <p>{state.auction.description}</p>
              <p className="text-sm text-gray m-0">Övriga upplysningar</p>
            </div>
          )}
        </div>
        <div className="w-100 mb-auto ml-05">
          <div className="bg-white p-4 mb-05">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h4 className="text-blue mb-0">
                {state.auction.manufacturer} {state.auction.model}
              </h4>
              {state.auction.can_edit && (
                <>
                  <DropdownSelect>
                    {state.auction.winning_bid &&
                      !state.auction.has_notified_winner && (
                        <span
                          role="button"
                          data-bs-toggle="modal"
                          data-bs-target="#notifyWinner"
                          onClick={() => notifyWinner()}
                        >
                          Notifiera vinnaren
                        </span>
                      )}
                    <span role="button" onClick={() => navigate('change')}>
                      Redigera
                    </span>
                    {state.auction.is_active && (
                      <span
                        role="button"
                        data-bs-toggle="modal"
                        data-bs-target="#cancelAuctionModal"
                      >
                        Avsluta
                      </span>
                    )}
                    <span
                      role="button"
                      data-bs-toggle="modal"
                      data-bs-target="#deleteAuctionModal"
                    >
                      Radera
                    </span>
                  </DropdownSelect>
                  <Modal
                    id="cancelAuctionModal"
                    title="Avsluta auktion?"
                    footer={
                      state.cancelAuctionSuccess ? (
                        <PrimaryButton type="button" data-bs-dismiss="modal">
                          Stäng
                        </PrimaryButton>
                      ) : (
                        <>
                          <LinkButton type="button" data-bs-dismiss="modal">
                            Avbryt
                          </LinkButton>
                          <PrimaryButton
                            type="button"
                            onClick={cancelAuction}
                            loading={state.isCancelingAuction}
                          >
                            Avsluta
                          </PrimaryButton>
                        </>
                      )
                    }
                  >
                    {state.hasCancelAuctionError && (
                      <div className="alert alert-danger" role="alert">
                        <div className="alert-content">Ett fel inträffade.</div>
                      </div>
                    )}
                    {state.cancelAuctionSuccess ? (
                      <p>Auktionen har avslutas.</p>
                    ) : (
                      <p>Är du säkert på att du vill avsluta auktionen?</p>
                    )}
                  </Modal>
                  <Modal
                    id="deleteAuctionModal"
                    title="Radera auktion?"
                    data-deleted={state.deleteAuctionSuccess}
                    footer={
                      state.deleteAuctionSuccess ? (
                        <PrimaryButton type="button" data-bs-dismiss="modal">
                          Stäng
                        </PrimaryButton>
                      ) : (
                        <>
                          <LinkButton type="button" data-bs-dismiss="modal">
                            Avbryt
                          </LinkButton>
                          <PrimaryButton
                            type="button"
                            onClick={deleteAuction}
                            loading={state.isDeletingAuction}
                          >
                            Radera
                          </PrimaryButton>
                        </>
                      )
                    }
                    onClose={(event) => {
                      if (event.target) {
                        const target = event.target as HTMLDivElement;

                        if (
                          target.dataset.deleted &&
                          target.dataset.deleted === 'true'
                        ) {
                          navigate('/auctions');
                        }
                      }
                    }}
                  >
                    {state.hasDeleteAuctionError && (
                      <div className="alert alert-danger" role="alert">
                        <div className="alert-content">Ett fel inträffade.</div>
                      </div>
                    )}
                    {state.deleteAuctionSuccess ? (
                      <p>Auktionen har raderats.</p>
                    ) : (
                      <p>Är du säker på att du vill radera auktionen?</p>
                    )}
                  </Modal>
                  <Modal
                    id="notifyWinner"
                    title="Notifikationen skickad!"
                    footer={
                      <LinkButton type="button" data-bs-dismiss="modal">
                        Stäng
                      </LinkButton>
                    }
                  >
                    {state.isNotifyingWinner ? (
                      <div className="d-flex justify-content-center">
                        <Spinner size="small" />
                      </div>
                    ) : state.notifyWinnerSuccess ? (
                      'Vinnare av auktionen blir meddelad inom kort.'
                    ) : (
                      'Något gick fel försök igen.'
                    )}
                  </Modal>
                </>
              )}
            </div>
            {userStore.me.role === Role.ServiceStation &&
              state.auction.is_active && (
                <form onSubmit={onSubmit} className="my-4">
                  {(state.auction.start_bid || state.auction.highest_bid) && (
                    <p>
                      Lägg{' '}
                      {state.auction.minimum_allowed_bid
                        ? formatCurrency(state.auction.minimum_allowed_bid)
                        : formatCurrency(state.auction.start_bid!)}{' '}
                      eller mer
                    </p>
                  )}
                  {state.bidSuccess && (
                    <div className="alert alert-success" role="alert">
                      <div className="alert-content">Bud accepterat</div>
                    </div>
                  )}
                  {state.errorMessages.length > 0 && (
                    <div className="alert alert-danger" role="alert">
                      <div className="alert-content">
                        <ul className="ul m-0">
                          {state.errorMessages.map((error, i) => (
                            <li key={i}>{error}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                  <Input
                    type="number"
                    suffix="kr"
                    error={errors.bid?.message}
                    {...register('bid', {
                      required: 'Du måste ange ett bud',
                    })}
                  />
                  {!userStore.me.has_accepted_terms && (
                    <>
                      {' '}
                      <Checkbox
                        label={
                          <>
                            Jag godkänner alla{' '}
                            <a
                              target="_blank"
                              href="/terms/purchase-conditions"
                              className="text-decoration-none"
                            >
                              villkor
                            </a>
                            .
                          </>
                        }
                        {...register('has_accepted_terms')}
                      />
                    </>
                  )}
                  <PrimaryButton
                    className="w-100 d-flex justify-content-between align-items-center"
                    loading={state.isSubmiting}
                  >
                    Lägg bud
                    <svg
                      width="27"
                      height="26"
                      viewBox="0 0 27 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className={classNames({
                        'd-none': state.isSubmiting,
                      })}
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M23.3748 4L10.5942 16.7297L4.57745 10.6409L1.93359 13.26L10.5769 22L26 6.63641L23.3748 4Z"
                        fill="white"
                      />
                    </svg>
                  </PrimaryButton>
                </form>
              )}
            <div className="d-flex flex-wrap auction-data bg-white">
              {!state.auction.hidden_bids && (
                <AuctionDataCell
                  label="Högsta bud"
                  value={
                    state.auction.highest_bid
                      ? formatCurrency(state.auction.highest_bid)
                      : '-'
                  }
                />
              )}
              {!state.auction.hidden_reservation_price && (
                <AuctionDataCell
                  label="Reservationspris"
                  value={
                    state.auction.reservation_price
                      ? formatCurrency(state.auction.reservation_price)
                      : '-'
                  }
                />
              )}
              <AuctionDataCell
                label="Start"
                value={
                  state.auction.start_dt &&
                  dateTimeString(state.auction.start_dt)
                }
              />
              <AuctionDataCell
                label="Slutar"
                value={
                  state.auction.end_dt && dateTimeString(state.auction.end_dt)
                }
              />
              <AuctionDataCell
                label={
                  (state.auction.categories?.length ?? 0) > 1
                    ? 'Kategorier'
                    : 'Kategori'
                }
                value={
                  state.auction.categories?.map((c) => (
                    <>
                      {c.name}
                      <br />
                    </>
                  )) ?? 'Ingen kategori'
                }
              />
              <AuctionDataCell label="Obj.nr" value={state.auction.number} />
              <AuctionDataCell
                label="Uppställningsplats"
                value={state.auction.staging_area_name}
                fullWidth={true}
              />
            </div>
          </div>
          <div className="bg-white p-4 mb-05">
            <div className="d-flex flex-wrap object-data bg-white">
              {state.auction.model_year && (
                <AuctionDataCell
                  label="Årsmodell"
                  value={state.auction.model_year}
                />
              )}
              {state.auction.mileage && (
                <AuctionDataCell
                  label="Mätarställning"
                  value={state.auction.mileage}
                />
              )}
              {state.auction.chassis_number && (
                <AuctionDataCell
                  label="Chassi.nr"
                  value={state.auction.chassis_number}
                />
              )}
              <AuctionDataCell
                label="Fabrikat"
                value={state.auction.manufacturer}
              />
              {state.auction.model && (
                <AuctionDataCell label="Modell" value={state.auction.model} />
              )}
              {state.auction.registration_number && (
                <AuctionDataCell
                  label="Reg.nr"
                  value={state.auction.registration_number}
                />
              )}
              <AuctionDataCell
                label="Säljes för"
                value={DAMAGE_CONDITION_TEXTS[state.auction.damage_condition]}
              />
            </div>
          </div>
          <div className="bg-white p-4 mb-05">
            <div className="d-flex flex-wrap company-data bg-white">
              <AuctionDataCell
                label="Bolag"
                value={state.auction.company_name}
                fullWidth={true}
              />
              {userStore.me.role >= Role.CaseManager &&
                state.auction.created_by && (
                  <>
                    <AuctionDataCell
                      label="Handläggare"
                      value={state.auction.created_by.fullName}
                      fullWidth={true}
                    />
                    <AuctionDataCell
                      label="Handläggarens e-post"
                      value={state.auction.created_by.email}
                      fullWidth={true}
                    />
                  </>
                )}
            </div>
          </div>
          {state.auction.files.length > 0 && (
            <div className="bg-white p-4">
              <div className="px-4 py-05">
                <div className="d-flex flex-wrap">
                  {state.auction.files.map((file) => (
                    <a
                      className="d-flex align-items-center mr-1"
                      href={file.file}
                      target="_blank"
                      key={file.id}
                      rel="noreferrer"
                    >
                      <svg
                        width="20"
                        height="21"
                        viewBox="0 0 20 21"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-shrink-0"
                      >
                        <path
                          d="M8.892 14.5099C9.98427 16.5507 11.2598 17.0604 13.0294 16.2352C14.799 15.41 15.2285 14.1053 14.3855 11.9989L11.9017 6.67218L13.4122 5.96782L15.9143 11.3366C17.0899 14.2695 16.3467 16.5273 13.7338 17.7457C11.1209 18.9641 8.91357 18.0822 7.40202 15.2554L3.87286 7.68704L3.83887 7.60232C3.2297 5.80469 3.89408 4.34902 5.67248 3.51974C7.4477 2.69195 8.99361 3.11118 9.99754 4.71941L10.0459 4.80851L13.5751 12.3768L13.6119 12.4701C14.0267 13.739 13.6931 14.7945 12.5961 15.306C11.4996 15.8173 10.4765 15.3953 9.76953 14.2631L9.72113 14.1739L7.23724 8.84723L8.74776 8.14287L11.2092 13.4215C11.4872 13.8558 11.6407 13.9126 11.8918 13.7955C12.1424 13.6786 12.1972 13.5254 12.0424 13.0337L8.55797 5.56133C8.01508 4.71757 7.38746 4.559 6.37684 5.03026C5.36782 5.50077 5.09441 6.07528 5.40373 7.02636L8.892 14.5099Z"
                          fill="#005AA0"
                        />
                      </svg>
                      {file.name}
                    </a>
                  ))}
                </div>
                <p className="text-gray text-sm mt-025 mb-0">Övriga dokument</p>
              </div>
            </div>
          )}
          {userStore.me.role >= Role.CaseManager && (
            <>
              {bidStore.bids && bidStore.bids.length > 0 ? (
                <>
                  <div className="timeline mt-05">
                    <ul className="timeline-list">
                      {bidStore.bids.map((bid) => (
                        <li key={bid.id} className="timeline-item">
                          <div className="timeline-container">
                            <div className="timeline-header d-flex align-items-center">
                              <div>
                                <div
                                  className={classNames('timeline-spacing', {
                                    'is-ongoing': bid.is_winning_bid,
                                  })}
                                >
                                  <div
                                    className={classNames('timeline-icon', {
                                      'is-done': !bid.is_winning_bid,
                                    })}
                                  >
                                    {bid.is_winning_bid ? (
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M17 13.088C15.345 13.247 14.154 12.797 12.805 12.284C11.273 11.702 9.547 11.06 7 11.241V5.341C9.12 5.003 10.55 5.574 12.052 6.188C13.428 6.751 14.966 7.375 17 7.227V13.088ZM17.795 5.097C15.728 5.528 14.375 4.977 12.809 4.337C11.253 3.701 9.501 2.999 7 3.32V2.5H5V20.533H7V13.246C9.184 13.06 10.605 13.589 12.094 14.154C13.354 14.632 14.644 15.123 16.317 15.123C16.894 15.123 17.516 15.065 18.197 14.928L19 14.766V4.844L17.795 5.097Z"
                                          fill="#005AA0"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M16.907 6.60059L10.267 13.2146L7.32402 10.2336L5.90002 11.6386L10.253 16.0476L18.316 8.01859L16.907 6.60059Z"
                                          fill="white"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                </div>
                                <h3 className="timeline-heading">
                                  {formatCurrency(bid.bid)}
                                </h3>
                                <small className="text-muted">
                                  {formatDate(new Date(bid.created_at)).date}:{' '}
                                  {bid.is_winning_bid && 'Vinnande bud - '}
                                  {bid.service_station_name}
                                </small>
                              </div>
                              {state.auction?.can_edit && (
                                <DropdownSelect className="ml-auto">
                                  {!bid.is_winning_bid &&
                                    !state.auction!.is_active && (
                                      <span
                                        role="button"
                                        onClick={() => {
                                          state.bidId = bid.id;
                                          selectWinner();
                                        }}
                                        data-bs-toggle="modal"
                                        data-bs-target="#selectWinnerModal"
                                      >
                                        Välj som vinnare
                                      </span>
                                    )}
                                  {state.auction!.is_active && (
                                    <span
                                      role="button"
                                      onClick={() => (state.bidId = bid.id)}
                                      data-bs-toggle="modal"
                                      data-bs-target="#deleteBidModal"
                                    >
                                      Ta bort bud
                                    </span>
                                  )}
                                </DropdownSelect>
                              )}
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {hasMore && (
                    <div className="bg-white text-center p-1">
                      {state.isFetchingMoreBids ? (
                        <Spinner size="tiny" />
                      ) : (
                        <span
                          className="load-more-link"
                          role="button"
                          onClick={fetchMoreBids}
                        >
                          Visa fler
                        </span>
                      )}
                    </div>
                  )}
                  <Modal
                    id="selectWinnerModal"
                    title={
                      state.isSelectingWinner
                        ? ''
                        : state.selectWinnerSuccess
                        ? 'Vinnare vald'
                        : 'Ett fel inträffade'
                    }
                    footer={
                      <LinkButton type="button" data-bs-dismiss="modal">
                        Stäng
                      </LinkButton>
                    }
                  >
                    {state.hasSelectWinnerError ? (
                      <div className="alert alert-danger" role="alert">
                        <div className="alert-content">Ett fel inträffade.</div>
                      </div>
                    ) : state.selectWinnerSuccess ? (
                      <p>Vinnaren har valts.</p>
                    ) : (
                      <div className="d-flex justify-content-center">
                        <Spinner size="small" />
                      </div>
                    )}
                  </Modal>
                  <Modal
                    id="deleteBidModal"
                    title="Ta bort bud?"
                    footer={
                      state.deleteBidSuccess ? (
                        <PrimaryButton
                          type="button"
                          onClick={() => (state.deleteBidSuccess = false)}
                          data-bs-dismiss="modal"
                        >
                          Stäng
                        </PrimaryButton>
                      ) : (
                        <>
                          <LinkButton type="button" data-bs-dismiss="modal">
                            Avbryt
                          </LinkButton>
                          <PrimaryButton
                            type="button"
                            onClick={deleteBid}
                            loading={state.isDeletingBid}
                          >
                            Ta bort bud
                          </PrimaryButton>
                        </>
                      )
                    }
                  >
                    {state.hasDeleteBidError && (
                      <div className="alert alert-danger" role="alert">
                        <div className="alert-content">Ett fel inträffade.</div>
                      </div>
                    )}
                    {state.deleteBidSuccess ? (
                      <p>Budet har tagits bort.</p>
                    ) : (
                      <p>Är du säkert på att du vi ta bort detta bud?</p>
                    )}
                  </Modal>
                </>
              ) : (
                <div className="bg-white text-center py-08 mt-05">
                  Det finns inga bud att visa.
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
});
