import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Checkbox,
  Input,
  LinkButton,
  Modal,
  PrimaryButton,
  RadioButton,
  RadioGroup,
  SecondaryButton,
} from '../components';
import { useStore } from '../hooks';
import { ServiceStationInstance } from '../models';

type FormData = {
  number: string;
  name: string;
  street: string;
  postal: string;
  city: string;
  is_staging_area: string;
  category_ids: string[];
  user: {
    first_name: string;
    last_name: string;
    email: string;
  };
};

export const ServiceStationDetailPage = observer(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    companyStore: { categoryOptions },
    serviceStationStore,
  } = useStore();

  const state = useLocalObservable<{
    serviceStation: ServiceStationInstance | null;
    isSubmiting: boolean;
    hasFormError: boolean;
    isSendingPasswordResetEmail: boolean;
    hasSendingPasswordResetEmailError: boolean;
    sendPasswordSuccess: boolean;
  }>(() => ({
    serviceStation: null,
    isSubmiting: false,
    hasFormError: false,
    isSendingPasswordResetEmail: false,
    hasSendingPasswordResetEmailError: false,
    sendPasswordSuccess: false,
  }));

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    setError,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { is_staging_area: '0', category_ids: [] },
  });

  const fetchServiceStation = async () => {
    const serviceStation = await serviceStationStore.fetchServiceStation(id!);
    state.serviceStation = serviceStation;

    setValue(
      'is_staging_area',
      state.serviceStation.is_staging_area ? '1' : '0',
    );
    setValue(
      'category_ids',
      state.serviceStation.categories.map((c) => c.id),
    );
  };

  const sendPasswordResetEmail = async () => {
    if (state.isSendingPasswordResetEmail || id === null) return;

    state.isSendingPasswordResetEmail = true;
    state.hasSendingPasswordResetEmailError = false;

    try {
      await serviceStationStore.sendPasswordResetEmail(id!);
      state.sendPasswordSuccess = true;
    } catch (e) {
      state.hasSendingPasswordResetEmailError = true;
    } finally {
      state.isSendingPasswordResetEmail = false;
    }
  };

  const onSubmit = handleSubmit(async ({ ...data }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.hasFormError = false;

    try {
      data.category_ids = data.category_ids.filter((id) => id !== '');
      const serviceStation = await serviceStationStore.updateServiceStation(
        id!,
        data,
      );
      state.serviceStation = serviceStation;
      navigate('/service-stations');
    } catch (error: any) {
      state.hasFormError = true;

      const {
        data: { email },
      } = error.response;

      if (email) {
        setError('user.email', { message: email[0] });
      }
    } finally {
      state.isSubmiting = false;
    }
  });

  useEffect(() => {
    fetchServiceStation();
  }, [id]);

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
    {
      title: 'Verkstäder',
      href: '/service-stations',
    },
  ];

  if (state.serviceStation === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active={state.serviceStation.name} />
      <h1 className="display-2 mt-15">{state.serviceStation.name}</h1>
      <div className="pt-2">
        <form onSubmit={onSubmit}>
          <div className="bg-white p-2">
            {state.hasFormError && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-content">Ett fel inträffade.</div>
              </div>
            )}
            <div className="row">
              <div className="col col-6">
                <Input
                  type="text"
                  label="Verkstadsnummer *"
                  defaultValue={state.serviceStation.number}
                  error={errors.number?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('number', {
                    required: 'Du måste ange verkstadsnummer',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Verkstadsnamn *"
                  defaultValue={state.serviceStation.name}
                  error={errors.name?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('name', {
                    required: 'Du måste ange verkstadens namn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Gatuadress"
                  defaultValue={state.serviceStation.street ?? ''}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('street')}
                />
              </div>
              <div className="col col-3">
                <Input
                  type="number"
                  label="Postnummer"
                  defaultValue={state.serviceStation.postal ?? ''}
                  error={errors.postal?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('postal', {
                    pattern: {
                      value: /^[0-9]{5}$/i,
                      message: 'Ange i formatet 12345',
                    },
                  })}
                />
              </div>
              <div className="col col-3">
                <Input
                  type="text"
                  label="Postort"
                  defaultValue={state.serviceStation.city ?? ''}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('city')}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson förnamn *"
                  defaultValue={state.serviceStation.user?.first_name ?? ''}
                  error={errors.user?.first_name?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('user.first_name', {
                    required: 'Du måste ange förnamn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson efternamn *"
                  defaultValue={state.serviceStation.user?.last_name ?? ''}
                  error={errors.user?.last_name?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('user.last_name', {
                    required: 'Du måste ange efternamn',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Kontaktperson e-post *"
                  defaultValue={state.serviceStation.user?.email ?? ''}
                  error={errors.user?.email?.message}
                  disabled={!state.serviceStation?.can_edit}
                  {...register('user.email', {
                    required: 'Du måste ange e-postadress',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Ange en giltig e-post',
                    },
                  })}
                />
              </div>
              <div className="col col-6">
                <RadioGroup
                  label="Uppställningsplats *"
                  error={errors.is_staging_area?.message}
                >
                  <RadioButton
                    label="Ja"
                    value="1"
                    active={watch('is_staging_area') === '1'}
                    disabled={!state.serviceStation?.can_edit}
                    {...register('is_staging_area', {
                      required:
                        'Du måste välja om verkstaden är uppställningsplats',
                    })}
                  />
                  <RadioButton
                    label="Nej"
                    value="0"
                    active={watch('is_staging_area') === '0'}
                    disabled={!state.serviceStation?.can_edit}
                    {...register('is_staging_area', {
                      required:
                        'Du måste välja om verkstaden är uppställningsplats',
                    })}
                  />
                </RadioGroup>
              </div>
            </div>
            <span className="d-block label mb-05">Kategorier</span>
            <div className="category-container">
              {categoryOptions.map((category, index) => (
                <Checkbox
                  key={index}
                  label={category.title}
                  defaultChecked={
                    state.serviceStation!.categories.filter(
                      (c) => c.id === category.value,
                    ).length > 0
                  }
                  disabled={!state.serviceStation?.can_edit}
                  onChange={(e) => {
                    let category_ids = getValues('category_ids');

                    if (e.target.checked) {
                      category_ids.push(category.value);
                    } else {
                      category_ids = category_ids.filter(
                        (category_id) => category_id !== category.value,
                      );
                    }

                    setValue('category_ids', category_ids);
                  }}
                />
              ))}
            </div>
            {state.serviceStation.can_edit &&
              state.serviceStation.user?.email && (
                <>
                  <SecondaryButton
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#resetPasswordModal"
                  >
                    Återställ lösenord
                  </SecondaryButton>
                  <Modal
                    id="resetPasswordModal"
                    title={
                      state.sendPasswordSuccess
                        ? 'Skickad'
                        : 'Skicka återställningslänk'
                    }
                    footer={
                      state.sendPasswordSuccess ? (
                        <PrimaryButton
                          type="button"
                          onClick={() => (state.sendPasswordSuccess = false)}
                          data-bs-dismiss="modal"
                        >
                          Stäng
                        </PrimaryButton>
                      ) : (
                        <>
                          <LinkButton type="button" data-bs-dismiss="modal">
                            Avbryt
                          </LinkButton>
                          <PrimaryButton
                            type="button"
                            onClick={sendPasswordResetEmail}
                            loading={state.isSendingPasswordResetEmail}
                          >
                            Skicka
                          </PrimaryButton>
                        </>
                      )
                    }
                  >
                    {state.hasSendingPasswordResetEmailError && (
                      <div className="alert alert-danger" role="alert">
                        <div className="alert-content">Ett fel inträffade.</div>
                      </div>
                    )}
                    {state.sendPasswordSuccess ? (
                      <>
                        Ett mail med information om återställning av lösenord
                        har skickats till{' '}
                        <b>{state.serviceStation.user.email}</b>
                      </>
                    ) : (
                      <>
                        <p>
                          Ett mail kommer att skickas till{' '}
                          <b>{state.serviceStation.user.email}</b> med en länk
                          där användaren kan ange ett nytt lösenord.
                        </p>
                        <p>Vill du fortsätta?</p>
                      </>
                    )}
                  </Modal>
                </>
              )}
          </div>
          <div className="d-flex justify-content-between bg-white p-15 mt-025">
            <Link to="/service-stations">Tillbaka</Link>
            {state.serviceStation.can_edit && (
              <PrimaryButton loading={state.isSubmiting}>Spara</PrimaryButton>
            )}
          </div>
        </form>
      </div>
    </div>
  );
});
