import classNames from 'classnames';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  Breadcrumbs,
  Datepicker,
  Input,
  PrimaryButton,
  RadioButton,
  RadioGroup,
  Select,
  Textarea,
  Upload,
  UploadPlaceholder,
} from '../components';
import { useStore } from '../hooks';
import { AuctionInstance } from '../models';
import { DamageConditionType, DAMAGE_CONDITION_TEXTS } from '../types';

type FormData = {
  number: number;
  category_ids: string[];
  registration_number: string;
  model_year: number;
  chassis_number: string;
  manufacturer: string;
  model: string;
  mileage: number;
  staging_area: string;
  staging_area_other: string;
  description: string;
  is_global: string;
  damage_condition: string;
  reservation_price: number;
  hidden_reservation_price: string;
  start_bid: number;
  hidden_bids: string;
  start_dt: Date | string;
  start_time: string;
  end_dt: Date | string;
  end_time: string;
  image: File;
  additional_images: File[];
  files: File[];
};

export const AddAuctionPage = observer(() => {
  let { id } = useParams();
  const navigate = useNavigate();
  const {
    auctionStore,
    companyStore: {
      company,
      categoryOptions,
      stagingAreaOptions,
      fetchCompany,
    },
  } = useStore();

  const state = useLocalObservable<{
    auction: AuctionInstance | null;
    isSubmiting: boolean;
    imageToRemove: string | null;
    imagesToRemove: string[];
    filesToRemove: string[];
  }>(() => ({
    auction: null,
    isSubmiting: false,
    imageToRemove: null,
    imagesToRemove: [],
    filesToRemove: [],
  }));

  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      is_global: '0',
      damage_condition: Object.values(DamageConditionType)[0],
      hidden_bids: '1',
      hidden_reservation_price: '1',
    },
  });

  const fetchAuction = async () => {
    state.auction = await auctionStore.fetchAuction(id!);

    Object.keys(state.auction).forEach((key) => {
      const value = state.auction![key as keyof AuctionInstance];

      if (typeof value === 'boolean') {
        setValue(key as keyof FormData, value ? '1' : '0');
      } else if (key === 'start_dt') {
        const date = new Date(value);
        setValue('start_dt', date.toLocaleDateString('sv-SE'));
        setValue('start_time', date.toLocaleTimeString('sv-SE'));
      } else if (key === 'end_dt') {
        const date = new Date(value);
        setValue('end_dt', date.toLocaleDateString('sv-SE'));
        setValue('end_time', date.toLocaleTimeString('sv-SE'));
      } else if (key === 'staging_area' && !value) {
        setValue(key, 'other');
      } else if (
        key !== 'additional_images' &&
        key !== 'files' &&
        key !== 'image'
      ) {
        setValue(key as keyof FormData, value || '');
      }
    });
  };

  useEffect(() => {
    if (id) {
      fetchAuction();
    }
  }, [id]);

  // Set end date according to company settings
  const watchStartDate = watch('start_dt');
  useEffect(() => {
    if (watchStartDate && !id) {
      const startDate = new Date(watchStartDate);

      startDate.setDate(
        startDate.getDate() + company!.default_end_dt_increment_days,
      );

      setValue('end_dt', startDate.toLocaleDateString('sv-SE'));
    }
  }, [watchStartDate]);

  useEffect(() => {
    if (company) {
      setValue('start_time', company!.default_end_dt_increment_time);
      setValue('end_time', company!.default_end_dt_increment_time);
    }
  }, [company]);

  const validateRegistrationOrChassis = () => {
    const [registrationNumber, chassisNumber] = getValues([
      'registration_number',
      'chassis_number',
    ]);

    return (
      !(registrationNumber && chassisNumber) &&
      (Boolean(registrationNumber) || Boolean(chassisNumber))
    );
  };

  const onSubmit = handleSubmit(async (auction: any) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;

    try {
      const {
        image,
        additional_images,
        files,
        start_dt,
        start_time,
        end_dt,
        end_time,
        category_ids,
        ...rest
      } = auction;

      // Set datetime values
      rest.start_dt = `${start_dt}T${start_time}`;
      rest.end_dt = `${end_dt}T${end_time}`;

      // Removing staging_area if value is 'other' to use staging_area_other instead
      if (rest.staging_area === 'other') {
        rest.staging_area = '';
      }

      if (state.imageToRemove !== null) {
        rest.image = '';
      }

      const data = new FormData();
      Object.keys(rest).forEach((key) => data.append(key, rest[key]));

      if (image.length) {
        data.append('image', image[0]);
      }

      category_ids.forEach((category: string, i: number) => {
        data.append(`category_ids[${i}]`, category);
      });

      if (id) {
        await auctionStore.updateAuction(data, id);
      } else {
        const newAcution = await auctionStore.createAuction(data);
        id = newAcution.id;
      }

      // Upload images and files
      if (id && (additional_images.length || files.length)) {
        Promise.all([
          auctionStore.createAuctionImages(id, additional_images),
          auctionStore.createAuctionFiles(id, files),
        ]);
      }

      if (state.imagesToRemove.length > 0) {
        state.imagesToRemove.forEach(async (imageToRemove) => {
          await auctionStore.removeImage(id!, imageToRemove);
        });
      }

      if (state.filesToRemove.length > 0) {
        state.filesToRemove.forEach(async (fileToRemove) => {
          await auctionStore.removeFile(id!, fileToRemove);
        });
      }

      await fetchCompany();

      if (id) {
        navigate(`/auctions/${id}`);
      } else {
        navigate('/auctions');
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      state.isSubmiting = false;
    }
  });

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
    {
      title: 'Auktioner',
      href: '/auctions',
    },
  ];

  if (id && state.auction === null) return null;

  return (
    <div>
      <Breadcrumbs
        items={breadcrumbs}
        active={`${id ? 'Uppdatera' : 'Ny'} auktion`}
      />
      <h1 className="display-2 mt-15">{id ? 'Uppdatera' : 'Ny'} auktion</h1>
      <div className="pt-2">
        <form onSubmit={onSubmit}>
          <div className="bg-white p-2">
            <div className="row">
              <div className="col col-6">
                <Input
                  type="text"
                  label="Objektsnummer"
                  defaultValue={company?.next_auction_number}
                  disabled
                />
              </div>
              {categoryOptions && (
                <div className="col col-6">
                  <Controller
                    control={control}
                    name="category_ids"
                    rules={{ required: 'Du måste välja en kategori' }}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        label="Kategorier *"
                        placeholder="Välj kategorier"
                        multiple={true}
                        options={categoryOptions}
                        selectedOptions={state.auction?.categories?.map(
                          (c) => c.id,
                        )}
                        error={errors.category_ids?.message}
                        onChange={(e) => {
                          const options = e.target.options;
                          const categories = [];

                          for (let i = 0, l = options.length; i < l; i++) {
                            if (options[i].value && options[i].selected) {
                              categories.push(options[i].value);
                            }
                          }

                          onChange(categories);
                        }}
                      />
                    )}
                  />
                </div>
              )}
              <div className="col col-4">
                <Input
                  type="text"
                  label="Reg.nr"
                  reg
                  defaultValue={state.auction?.registration_number || ''}
                  error={
                    errors.registration_number?.type === 'validate' &&
                    'Du måste fylla i antingen reg.nr eller chassi.nr'
                  }
                  {...register('registration_number', {
                    validate: () => validateRegistrationOrChassis(),
                  })}
                />
              </div>
              <div className="col col-4">
                <Input
                  type="number"
                  label="Årsmodell"
                  defaultValue={state.auction?.model_year || ''}
                  error={errors.model_year?.message}
                  {...register('model_year')}
                />
              </div>
              <div className="col col-4">
                <Input
                  type="text"
                  label="Chassi.nr"
                  defaultValue={state.auction?.chassis_number || ''}
                  error={
                    errors.chassis_number?.type === 'validate' &&
                    'Du måste fylla i antingen reg.nr eller chassi.nr'
                  }
                  {...register('chassis_number', {
                    validate: () => validateRegistrationOrChassis(),
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Fabrikat *"
                  defaultValue={state.auction?.manufacturer || ''}
                  error={errors.manufacturer?.message}
                  {...register('manufacturer', {
                    required: 'Du måste ange ett fabrikat',
                  })}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Modell"
                  defaultValue={state.auction?.model || ''}
                  error={errors.model?.message}
                  {...register('model')}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="number"
                  label="Mätarställning (mil/timmar)"
                  defaultValue={state.auction?.mileage || ''}
                  error={errors.mileage?.message}
                  {...register('mileage')}
                />
              </div>
              <div
                className={classNames('col', {
                  'col-3': watch('staging_area') === 'other',
                })}
              >
                <Select
                  label="Uppställningsplats *"
                  placeholder="Välj uppställningsplats"
                  options={[
                    ...stagingAreaOptions,
                    { value: 'other', title: 'Annat' },
                  ]}
                  error={errors.staging_area?.message}
                  {...register('staging_area', {
                    required: 'Du måste ange en uppställningsplats',
                  })}
                />
              </div>
              {watch('staging_area') === 'other' && (
                <div className="col col-3">
                  <Input
                    type="text"
                    label="Annat *"
                    placeholder="Ange annan uppställningsplats"
                    defaultValue={state.auction?.staging_area_name || ''}
                    error={errors.staging_area_other?.message}
                    {...register('staging_area_other', {
                      required: 'Du måste ange en uppställningsplats',
                    })}
                  />
                </div>
              )}
              <div className="col col-6">
                <RadioGroup
                  label="Säljområde *"
                  error={errors.is_global?.message}
                >
                  <RadioButton
                    label="Egna verkstäder"
                    value="0"
                    active={watch('is_global') === '0'}
                    {...register('is_global', {
                      required: 'Du måste välja säljområde',
                    })}
                  />
                  <RadioButton
                    label="Alla verkstäder"
                    value="1"
                    active={watch('is_global') === '1'}
                    {...register('is_global', {
                      required: 'Du måste välja säljområde',
                    })}
                  />
                </RadioGroup>
              </div>
              <div className="col col-6">
                <RadioGroup
                  label="Säljes för *"
                  error={errors.damage_condition?.message}
                >
                  {Object.values(DamageConditionType).map(
                    (condition, index) => (
                      <RadioButton
                        label={DAMAGE_CONDITION_TEXTS[condition]}
                        value={condition}
                        active={watch('damage_condition') === condition}
                        key={index}
                        {...register('damage_condition', {
                          required: 'Du måste välja säljes för',
                        })}
                      />
                    ),
                  )}
                </RadioGroup>
              </div>
              <div className="col col-12">
                <Textarea
                  label="Övriga upplysningar"
                  defaultValue={state.auction?.description as string}
                  error={errors.description?.message}
                  {...register('description')}
                />
              </div>
              <div className="col col-3">
                <Input
                  type="number"
                  label="Reservationspris"
                  suffix="kr"
                  defaultValue={state.auction?.reservation_price ?? ''}
                  error={errors.reservation_price?.message}
                  {...register('reservation_price')}
                />
              </div>
              <div className="col col-3">
                <RadioGroup
                  label="Dolt reservationspris *"
                  error={errors.hidden_reservation_price?.message}
                >
                  <RadioButton
                    label="Ja"
                    value="1"
                    active={watch('hidden_reservation_price') === '1'}
                    {...register('hidden_reservation_price', {
                      required:
                        'Du måste välja om det ska vara dolt reservationspris',
                    })}
                  />
                  <RadioButton
                    label="Nej"
                    value="0"
                    active={watch('hidden_reservation_price') === '0'}
                    {...register('hidden_reservation_price', {
                      required:
                        'Du måste välja om det ska vara dolt reservationspris',
                    })}
                  />
                </RadioGroup>
              </div>
              <div className="col col-3">
                <Input
                  type="number"
                  label="Startbud"
                  suffix="kr"
                  defaultValue={state.auction?.start_bid as number}
                  error={errors.start_bid?.message}
                  {...register('start_bid')}
                />
              </div>
              <div className="col col-3">
                <RadioGroup
                  label="Dolda bud *"
                  error={errors.hidden_bids?.message}
                >
                  <RadioButton
                    label="Ja"
                    value="1"
                    active={watch('hidden_bids') === '1'}
                    {...register('hidden_bids', {
                      required: 'Du måste välja dolda bud',
                    })}
                  />
                  <RadioButton
                    label="Nej"
                    value="0"
                    active={watch('hidden_bids') === '0'}
                    {...register('hidden_bids', {
                      required: 'Du måste välja dolda bud',
                    })}
                  />
                </RadioGroup>
              </div>
              <div className="col col-3">
                <Datepicker
                  label="Startdatum *"
                  id="start_dt"
                  setValue={setValue}
                  defaultValue={watch('start_dt') as string}
                  error={errors.start_dt?.message}
                  {...register('start_dt', {
                    required: 'Du måste ange ett startdatum',
                  })}
                />
              </div>
              <div className="col col-3">
                <Input
                  label="Starttid *"
                  type="time"
                  error={errors.start_time?.message}
                  {...register('start_time', {
                    required: 'Du måste ange en starttid',
                  })}
                />
              </div>
              <div className="col col-3">
                <Datepicker
                  label="Slutdatum *"
                  id="end_dt"
                  setValue={setValue}
                  defaultValue={watch('end_dt') as string}
                  error={errors.end_dt?.message}
                  {...register('end_dt', {
                    required: 'Du måste ange ett slutdatum',
                  })}
                />
              </div>
              <div className="col col-3">
                <Input
                  label="Sluttid *"
                  type="time"
                  error={errors.end_time?.message}
                  {...register('end_time', {
                    required: 'Du måste ange en sluttid',
                  })}
                />
              </div>
              <div className="col col-6">
                <Upload
                  label="Huvudbild"
                  description="Här kan du bifoga auktionens huvudbild"
                  accept="image/jpg,image/jpeg,image/png"
                  error={errors.image?.message}
                  {...register('image')}
                />
                {state.auction?.thumb && (
                  <UploadPlaceholder
                    file={{
                      data: state.auction!.thumb,
                      type: 'image',
                      name: 'Huvudbild',
                      validation: state.imageToRemove
                        ? 'has-danger'
                        : 'has-valid',
                    }}
                    removeable
                    onRemoveClick={() =>
                      (state.imageToRemove = state.imageToRemove
                        ? ''
                        : state.auction!.id)
                    }
                  />
                )}
              </div>
              <div className="col col-6">
                <Upload
                  label="Galleribilder"
                  description="Här kan du bifoga mer bilder"
                  accept="image/jpg,image/jpeg,image/png"
                  error={errors.additional_images?.message}
                  {...register('additional_images')}
                  multiple={true}
                />
                {state.auction?.additional_images?.map((additional_image) => (
                  <UploadPlaceholder
                    key={additional_image.id}
                    file={{
                      data: additional_image.thumb,
                      type: 'image',
                      name: additional_image.id,
                      validation:
                        state.imagesToRemove.indexOf(additional_image.id) === -1
                          ? 'has-valid'
                          : 'has-danger',
                    }}
                    removeable
                    onRemoveClick={() => {
                      const index = state.imagesToRemove.indexOf(
                        additional_image.id,
                      );
                      index === -1
                        ? state.imagesToRemove.push(additional_image.id)
                        : state.imagesToRemove.splice(index, 1);
                    }}
                  />
                ))}
              </div>
              <div className="col col-6">
                <Upload
                  label="Övriga dokument"
                  description="Här kan du bifoga dokument"
                  error={errors.files?.message}
                  {...register('files')}
                  multiple={true}
                />
                {state.auction?.files?.map((file) => (
                  <UploadPlaceholder
                    key={file.id}
                    file={{
                      type: 'file',
                      name: file.name,
                      validation:
                        state.filesToRemove.indexOf(file.id) === -1
                          ? 'has-valid'
                          : 'has-danger',
                    }}
                    removeable
                    onRemoveClick={() => {
                      const index = state.filesToRemove.indexOf(file.id);
                      index === -1
                        ? state.filesToRemove.push(file.id)
                        : state.filesToRemove.splice(index, 1);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between bg-white p-15 mt-025">
            <Link to="/auctions">Tillbaka</Link>
            <PrimaryButton loading={state.isSubmiting} arrow>
              Spara
              <span className="d-block text-sm font-weight-normal font-base">
                och gå vidare
              </span>
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
});
