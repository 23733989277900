import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Breadcrumbs, Input, PrimaryButton } from '../components';
import { useStore } from '../hooks';

type FormData = {
  auction_end_dt_incrementation: number;
  bid_increment_intervals: {
    min_price: number | '';
    max_price: number | '';
    increment: number | '';
  }[];
  default_end_dt_increment_days: number;
  default_end_dt_increment_time: string;
  email: string;
  phone: string;
  street: string;
  postal: string;
  city: string;
  ended_auction_email: string;
  auction_summary_email: string;
};

export const CompanySettingsPage = observer(() => {
  const { companyStore } = useStore();
  const { company } = companyStore;

  const state = useLocalObservable(() => ({
    isSubmiting: false,
    submitSuccess: false,
    submitError: false,
  }));

  const {
    control,
    register,
    watch,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const { fields, replace, append } = useFieldArray({
    control,
    name: 'bid_increment_intervals',
  });

  const onSubmit = handleSubmit(async ({ ...data }) => {
    if (state.isSubmiting) return;

    state.isSubmiting = true;
    state.submitSuccess = false;
    state.submitError = false;

    try {
      const bid_increment_intervals = data.bid_increment_intervals.filter(
        (interval) =>
          interval.min_price !== '' &&
          interval.max_price !== '' &&
          interval.increment !== '',
      ) as { min_price: number; max_price: number; increment: number }[];

      await companyStore.updateCompany({
        ...data,
        bid_increment_intervals,
      });

      state.submitSuccess = true;
    } catch (error: any) {
      state.submitError = true;
    } finally {
      state.isSubmiting = false;
      window.scrollTo(0, 0);
    }
  });

  useEffect(() => {
    if (company) {
      replace(company.bid_increment_intervals);
      append(
        {
          min_price: '',
          max_price: '',
          increment: '',
        },
        {
          shouldFocus: false,
        },
      );
    }
  }, [company]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (value.bid_increment_intervals && name && type === 'change') {
        const intervalsCount = value.bid_increment_intervals!.length;
        const nameParts = name!.split('.');

        if (
          nameParts[0] === 'bid_increment_intervals' &&
          nameParts[1] === (intervalsCount - 1).toString()
        ) {
          append(
            {
              min_price: '',
              max_price: '',
              increment: '',
            },
            {
              shouldFocus: false,
            },
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const breadcrumbs = [
    {
      title: 'Hem',
      href: '/',
    },
  ];

  if (company === null) return null;

  return (
    <div>
      <Breadcrumbs items={breadcrumbs} active="Inställningar" />
      <h1 className="display-2 mt-15">Inställningar</h1>
      <div className="pt-2">
        <form onSubmit={onSubmit}>
          <div className="bg-white p-2">
            {state.submitSuccess && (
              <div className="alert alert-success" role="alert">
                <div className="alert-content">
                  Inställningarna har uppdaterats.
                </div>
              </div>
            )}
            {state.submitError && (
              <div className="alert alert-danger" role="alert">
                <div className="alert-content">
                  Ett fel inträffade när inställningarna skulle uppdateras.
                </div>
              </div>
            )}
            <div className="row mb-2">
              <div className="col-md-12">
                <h2 className="text-blue mt-0 mb-1">Kontaktuppgifter</h2>
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="E-postadress"
                  defaultValue={company.email ?? ''}
                  error={errors.email?.message}
                  {...register('email')}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Telefonnummer"
                  defaultValue={company.phone ?? ''}
                  error={errors.phone?.message}
                  {...register('phone')}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="Gatuadress"
                  defaultValue={company.street ?? ''}
                  error={errors.street?.message}
                  {...register('street')}
                />
              </div>
              <div className="col col-3">
                <Input
                  type="text"
                  label="Postnummer"
                  defaultValue={company.postal ?? ''}
                  error={errors.postal?.message}
                  {...register('postal')}
                />
              </div>
              <div className="col col-3">
                <Input
                  type="text"
                  label="Postort"
                  defaultValue={company.city ?? ''}
                  error={errors.city?.message}
                  {...register('city')}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <h2 className="text-blue mt-0 mb-1">E-postadresser</h2>
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="E-postadress för avslutade auktioner"
                  defaultValue={company.ended_auction_email ?? ''}
                  error={errors.ended_auction_email?.message}
                  {...register('ended_auction_email')}
                />
              </div>
              <div className="col col-6">
                <Input
                  type="text"
                  label="E-postadress för sammanställning av auktioner"
                  defaultValue={company.auction_summary_email ?? ''}
                  error={errors.auction_summary_email?.message}
                  {...register('auction_summary_email')}
                />
              </div>
            </div>
            <div className="row mb-2">
              <div className="col-md-12">
                <h2 className="text-blue mt-0 mb-1">Slutdatum och tid</h2>
              </div>
              <div className="col col-6">
                <Input
                  label="Slutar inom"
                  type="number"
                  id="default_end_dt_increment_days"
                  suffix="dagar"
                  defaultValue={company.default_end_dt_increment_days}
                  error={errors.default_end_dt_increment_days?.message}
                  {...register('default_end_dt_increment_days')}
                />
              </div>
              <div className="col col-6">
                <Input
                  label="Sluttid"
                  type="time"
                  defaultValue={company.default_end_dt_increment_time}
                  error={errors.default_end_dt_increment_time?.message}
                  {...register('default_end_dt_increment_time')}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <h2 className="text-blue mt-0 mb-1">Auktionsförlängning</h2>
                  <div className="row">
                    <div className="col col-12">
                      <Input
                        type="number"
                        label="Antal minuter sluttiden förlängs med när det kommer ett nytt bud in i slutskedet av en auktion"
                        suffix="minuter"
                        defaultValue={company.auction_end_dt_incrementation}
                        error={errors.auction_end_dt_incrementation?.message}
                        {...register(`auction_end_dt_incrementation`, {
                          required: 'Du måste ange antal minuter',
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-0">
                  <h2 className="text-blue mt-0 mb-1">Budökningsintervaller</h2>
                  {fields.map((field, index) => (
                    <div key={field.id} className="row">
                      <div className="col">
                        <Input
                          type="number"
                          label="Lägsta reservationspris"
                          suffix="kr"
                          defaultValue={field.min_price}
                          error={
                            errors.bid_increment_intervals?.[index]?.min_price
                              ?.message
                          }
                          {...register(
                            `bid_increment_intervals.${index}.min_price`,
                            {
                              validate: {
                                required: (value) => {
                                  if (
                                    value === '' &&
                                    (getValues(
                                      `bid_increment_intervals.${index}.max_price`,
                                    ) ||
                                      getValues(
                                        `bid_increment_intervals.${index}.increment`,
                                      ))
                                  )
                                    return 'Du måste ange intervallets lägsta pris';
                                  return true;
                                },
                                min: (value) => {
                                  value = parseInt(value.toString(), 10);
                                  if (value < 0)
                                    return 'Priset får inte vara mindre än noll';
                                  return true;
                                },
                                max: (value) => {
                                  value = parseInt(value.toString(), 10);
                                  if (
                                    value >=
                                      getValues(
                                        `bid_increment_intervals.${index}.max_price`,
                                      ) &&
                                    getValues(
                                      `bid_increment_intervals.${index}.max_price`,
                                    ) !== ''
                                  )
                                    return 'Priset måste vara mindre än intervallets högsta pris';
                                  return true;
                                },
                              },
                            },
                          )}
                        />
                      </div>
                      <div className="col">
                        <Input
                          type="number"
                          label="Högsta reservationspris"
                          suffix="kr"
                          defaultValue={field.max_price ? field.max_price : ''}
                          error={
                            errors.bid_increment_intervals?.[index]?.max_price
                              ?.message
                          }
                          {...register(
                            `bid_increment_intervals.${index}.max_price`,
                            {
                              validate: {
                                required: (value) => {
                                  if (
                                    value === '' &&
                                    (getValues(
                                      `bid_increment_intervals.${index}.min_price`,
                                    ) ||
                                      getValues(
                                        `bid_increment_intervals.${index}.increment`,
                                      ))
                                  )
                                    return 'Du måste ange intervallets högsta pris';
                                  return true;
                                },
                                min: (value) => {
                                  value = parseInt(value.toString(), 10);
                                  if (
                                    value <=
                                      getValues(
                                        `bid_increment_intervals.${index}.min_price`,
                                      ) &&
                                    getValues(
                                      `bid_increment_intervals.${index}.min_price`,
                                    ) !== ''
                                  )
                                    return 'Priset måste vara högre än intervallets lägsta pris';
                                  return true;
                                },
                              },
                            },
                          )}
                        />
                      </div>
                      <div className="col">
                        <Input
                          type="number"
                          label="Minsta höjning"
                          suffix="kr"
                          defaultValue={field.increment ? field.increment : ''}
                          error={
                            errors.bid_increment_intervals?.[index]?.increment
                              ?.message
                          }
                          {...register(
                            `bid_increment_intervals.${index}.increment`,
                            {
                              validate: {
                                required: (value) => {
                                  if (
                                    value === '' &&
                                    (getValues(
                                      `bid_increment_intervals.${index}.min_price`,
                                    ) ||
                                      getValues(
                                        `bid_increment_intervals.${index}.max_price`,
                                      ))
                                  )
                                    return 'Du måste ange lägsta höjning för intervallet';
                                  return true;
                                },
                              },
                            },
                          )}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end bg-white p-15 mt-025">
            <PrimaryButton loading={state.isSubmiting} arrow>
              Spara
            </PrimaryButton>
          </div>
        </form>
      </div>
    </div>
  );
});
