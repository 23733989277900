import { Instance, types as t } from 'mobx-state-tree';

export const User = t
  .model('User', {
    id: t.identifier,
    email: t.string,
    first_name: t.maybeNull(t.string),
    last_name: t.maybeNull(t.string),
    role: t.number,
    is_superuser: t.boolean,
    has_accepted_terms: t.boolean,
  })
  .views((self) => ({
    get fullName(): string {
      return `${self.first_name} ${self.last_name}`;
    },
  }));

export const ServiceStationUser = t
  .model('ServiceStationUser', {
    id: t.identifier,
    email: t.string,
    first_name: t.maybeNull(t.string),
    last_name: t.maybeNull(t.string),
  })
  .views((self) => ({
    get fullName(): string {
      return `${self.first_name} ${self.last_name}`;
    },
  }));

export const CreatedBy = t
  .model('CreatedBy', {
    first_name: t.maybeNull(t.string),
    last_name: t.maybeNull(t.string),
    email: t.maybeNull(t.string),
  })
  .views((self) => ({
    get fullName(): string {
      return `${self.first_name} ${self.last_name}`;
    },
  }));

export type UserInstance = Instance<typeof User>;
