import axios, { AxiosRequestConfig } from 'axios';
import { applySnapshot, flow, getRoot, types as t } from 'mobx-state-tree';
import { RootStore } from './RootStore';

export const AuthStore = t
  .model('AuthStore', {
    adfs_url: t.maybeNull(t.string),
    two_factor_uuid: t.maybeNull(t.string),
    access_token: t.maybeNull(t.string),
    refresh_token: t.maybeNull(t.string),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.refresh_token !== null;
    },
  }))
  .actions((self) => {
    const rootStore = getRoot<typeof RootStore>(self);

    let refreshTokensPromise: null | Promise<any> = null;
    const refreshTokens = flow(function* () {
      // No catch clause because we want the caller to handle the error.
      try {
        const res = yield axios({
          method: 'POST',
          url: '/api/auth/token/refresh',
          data: {
            refresh: self.refresh_token,
          },
        });

        self.access_token = res.data.access;
        self.refresh_token = res.data.refresh;
      } finally {
        refreshTokensPromise = null;
      }
    });

    return {
      // Multiple requests could fail at the same time because of an old
      // access_token, so we want to make sure only one token refresh
      // request is sent.
      refreshTokens() {
        if (refreshTokensPromise) return refreshTokensPromise;

        refreshTokensPromise = refreshTokens();
        return refreshTokensPromise;
      },
      fetchAdfsUrl: flow(function* () {
        const adfsUrlRes = yield axios({
          method: 'GET',
          url: '/api/auth/adfs',
        });

        self.adfs_url = adfsUrlRes.data.url;
      }),
      loginAdfs: flow(function* (authorization_code) {
        const tokenRes = yield axios({
          method: 'POST',
          url: '/api/auth/token/adfs',
          data: {
            authorization_code,
          },
        });

        self.access_token = tokenRes.data.access;
        self.refresh_token = tokenRes.data.refresh;

        rootStore.init();
      }),
      initializeTwoFactorLogin: flow(function* (email, password) {
        const twoFactorRes = yield axios({
          method: 'POST',
          url: '/api/auth/two-factor',
          data: {
            email,
            password,
          },
        });

        self.two_factor_uuid = twoFactorRes.data.uuid;
      }),
      loginTwoFactor: flow(function* (two_factor_code) {
        const tokenRes = yield axios({
          method: 'POST',
          url: '/api/auth/token/two-factor',
          data: {
            uuid: self.two_factor_uuid,
            two_factor_code,
          },
        });

        self.two_factor_uuid = null;
        self.access_token = tokenRes.data.access;
        self.refresh_token = tokenRes.data.refresh;

        rootStore.init();
      }),
      logOut() {
        rootStore.reset();
      },
      reset() {
        applySnapshot(self, {
          access_token: null,
          refresh_token: null,
        });
      },
    };
  })
  .actions((self) => {
    return {
      authRequest: flow(function* (conf: AxiosRequestConfig) {
        const authConf = {
          ...conf,
          headers: {
            ...conf.headers,
            Authorization: `Bearer ${self.access_token}`,
          },
        };

        try {
          return yield axios(authConf);
        } catch (error: any) {
          const { status } = error.response;

          // Throw the error to the caller if it's not an authorization error.
          if (status !== 401) {
            throw error;
          }

          try {
            yield self.refreshTokens();
          } catch (err) {
            // If refreshing of the tokens fail we have an old
            // refresh_token and we must log out the user.
            self.logOut();
            // We still throw the error so the caller doesn't
            // treat it as a successful request.
            throw err;
          }

          authConf.headers.Authorization = `Bearer ${self.access_token}`;

          return yield axios(authConf);
        }
      }),
    };
  });
