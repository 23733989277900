import { Instance, types as t } from 'mobx-state-tree';
import { Category } from './Category';
import { ServiceStationUser } from './User';

export const ServiceStation = t
  .model('ServiceStation', {
    id: t.identifier,
    number: t.string,
    name: t.string,
    street: t.maybeNull(t.string),
    postal: t.maybeNull(t.string),
    city: t.maybeNull(t.string),
    is_staging_area: t.boolean,
    categories: t.array(Category),
    silent_categories: t.array(Category),
    company_name: t.maybeNull(t.string),
    user: t.maybeNull(ServiceStationUser),
    can_edit: t.maybeNull(t.boolean),
  })
  .views((self) => ({
    get singleLineAddress(): string {
      return self.street || self.postal || self.city
        ? `${self.street ? self.street : ''}${
            self.street && (self.postal || self.city) ? ',' : ''
          } ${self.postal ? self.postal : ''} ${
            self.city ? self.city : ''
          }`.trim()
        : '';
    },
  }));

export type ServiceStationInstance = Instance<typeof ServiceStation>;
